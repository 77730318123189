body {
    background: url('./betman.png') no-repeat center 20px fixed;
    background-size: 50%;
    margin-top: 50%;
}

@media only screen and (min-width: 768px) {
    body {
        background: url('./betman.png') no-repeat center 20px fixed;
        background-size: 27%;
        margin-top: 23%;
    }
}